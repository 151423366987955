import { get } from 'lodash';
import { GetServerSideProps } from 'next';
import { getSessionById } from '../../services/sessions';
import { boutiquePathRouter } from '../../config/router';
import {
  ISessionDetails,
  IStore,
  IVirtualBoutiqueConfig
} from '../../interfaces';
import logger from '../../services/serverLogger';
import { getStoreById } from '../../services/stores';
import { getVirtualBoutiqueConfigByStoreId } from '../../services/virtualBoutique';
import { VirtualBoutiqueWrapper } from '../virtualboutique/[id]';
import Error from '../_error';
import PasswordProtection from '../../components/PasswordProtection';

const VirtualBoutique = ({
  config,
  store,
  session
}: {
  config: IVirtualBoutiqueConfig | undefined;
  store: IStore | undefined;
  session?: ISessionDetails;
}) => {
  if (!config || !store) {
    return <Error hasGetInitialPropsRun={true} err={null} />;
  }
  return config.passcode ? (
    <PasswordProtection correctPassword={config.passcode}>
      <VirtualBoutiqueWrapper config={config} store={store} session={session} />
    </PasswordProtection>
  ) : (
    <VirtualBoutiqueWrapper config={config} store={store} session={session} />
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  req,
  params,
  query
}) => {
  const host = req.headers.host || '';
  let brand = host.split('.')[0];

  if (host === 'experience.chopard.com') {
    brand = 'chopard';
  }

  const country = params.country as string;
  const locations = (params.locations || []) as string[];
  const path = [country, ...locations].join('/');
  const id = get(boutiquePathRouter, [brand, path]);
  let session = null;
  let config = null;
  let store = null;

  if (!id) {
    logger.warn(`Virtual Boutique for host ${host} at ${path} is not found`);
    return {
      props: {}
    };
  }
  try {
    config = await getVirtualBoutiqueConfigByStoreId(id);
    store = await getStoreById(id);
    if (query?.source === 'session' && query?.meeting)
      session = await getSessionById(query?.meeting as string);
  } catch (e) {
    console.error(e);
  }
  return {
    props: { config, store, session }
  };
};

export default VirtualBoutique;
